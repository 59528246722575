import Logo from "./logo";

export function AuthLayout({children}) {
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/*<img
                        className="mx-auto h-10 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                    />*/}
                    <Logo animated className="mx-auto w-24"/>
                    {/*<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                        Sign in to your account
                    </h2>*/}
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {children}
                </div>
            </div>
            {/*<div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
                <div
                    className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
                    <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
                        {children}
                    </div>
                </div>
                <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
                    <img
                        className="absolute inset-0 h-full w-full object-cover saturate-50"
                        src={backgroundImage}
                        alt=""
                        unoptimized
                    />
                </div>
            </div>*/}
        </>
    )
}
