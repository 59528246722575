export default function Logo({animated = false, className = ''}) {
    const gradient = {
        stopColor: '#000000',
        stopOpacity: 1
    };
    const triangleStyle = {
        fill: 'white',
        transformOrigin: '100px 100px',
        animation: 'rotateTriangle 3s ease-in-out 5s 1',
        animationFillMode: 'forwards',
        animationPlayState: animated ? 'running' : 'unset',
    };
    const eyeStyle = {
        transformOrigin: 'center',
        animation: 'blink 2s ease-in-out 40s 1',
        animationPlayState: animated ? 'running' : 'unset',
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className={className}>
            <defs>
                <radialGradient id="eyeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={gradient}/>
                    <stop offset="100%" style={{...gradient, stopColor: '#333333'}}/>
                </radialGradient>
            </defs>
            <title>AI Chatbot Logo</title>
            <polygon points="100,50 50,150 150,150" style={triangleStyle} className="triangle"/>
            <circle cx="100" cy="100" r="15" fill="url(#eyeGradient)" style={eyeStyle} className="eye"/>
        </svg>
    );
}
