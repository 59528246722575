import {classNames} from "../utils";

const formClasses =
    'block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6'

function Label({id, children}) {
    return (
        <label
            htmlFor={id}
            className="block text-sm font-medium leading-6 text-white"
        >
            {children}
        </label>
    )
}

export function TextField({
                              id,
                              label,
                              type = 'text',
                              className = '',
                              ...props
                          }) {
    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <div className="mt-2">
                <input id={id} type={type} {...props} className={formClasses}/>
            </div>
        </div>
    )
}

export function SelectField({id, label, className = '', ...props}) {
    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <div className="mt-2">
                <select id={id} {...props} className={classNames(formClasses, 'pr-8')}/>
            </div>
        </div>
    )
}
