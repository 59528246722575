// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyChRLwKBUjz8nl1YXGnFveo0s-NDlu-Ebk",
    authDomain: "smartbot-si.firebaseapp.com",
    projectId: "smartbot-si",
    storageBucket: "smartbot-si.appspot.com",
    messagingSenderId: "725380105801",
    appId: "1:725380105801:web:60e12f6daf2cf9369ba198"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);
